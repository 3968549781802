$(document).ready(function(){

    $('input.primary-focus').focus();

    /**
     * function to append fixed class to header.main after scrolling
     */
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 190) {
            $('header.main').addClass('fixed');
            // fill space that .region-header-second used to take up
            $('body').css('padding-top', $('header.main').height() + 'px');
        } else {
            $('header.main').removeClass('fixed');
            // remove space that .region-header-second used to take up
            $('body').css('padding-top', '0');
        }
    });

});
